<template>
   <section class="cont agreementList">
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>业务中台</el-breadcrumb-item>
            <el-breadcrumb-item>协议单位管理</el-breadcrumb-item>
            <el-breadcrumb-item>协议单位列表</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box">
         <!-- 查询框 -->
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>订单状态：</label>
                  <el-input  class="width-220" placeholder="协议单位" v-model="agreement" clearable></el-input>
               </el-row>
               <el-row class="search-item">
                  <label>联系人：</label>
                  <el-input  class="width-220" placeholder="联系人" v-model="contacts" clearable></el-input>
               </el-row>
               <el-row class="search-item">
                  <label>联系电话：</label>
                  <el-input  class="width-220" placeholder="联系电话" v-model="mobile" clearable></el-input>
               </el-row>
               <el-button class="bg-gradient" type="primary"  @click="searchEvent">查询</el-button>
               <el-button  type="primary" plain @click="handleReset">重置</el-button>
               <el-button class="bg-gradient" type="primary" @click="addEdit(true)">创建</el-button>
            </el-row>
         </el-row>

         <el-tabs v-model="tabState" @tab-click="tabClick" class="tab-box" style="width: 190px;">
            <el-tab-pane label="全部" name="all"></el-tab-pane>
            <el-tab-pane label="正常" name="normal"></el-tab-pane>
            <el-tab-pane label="禁用" name="disable"></el-tab-pane>
         </el-tabs>

         <el-row class="table-box">
            <el-table
                  v-loading="loading"
                  :data="tableData"
                  border fit
                  style="width: 100%"
                  :stripe="true"
                  :header-cell-style="{background: '#f5f8f9'}">
               <el-table-column label="序号" width="60" type="index"></el-table-column>
               <el-table-column label="协议单位名称" prop="name" width="125"></el-table-column>
               <el-table-column label="联系人"></el-table-column>
               <el-table-column label="联系电话" prop="mobile" width="120"></el-table-column>
               <el-table-column label="价格模板名" width="110"></el-table-column>
               <el-table-column label="管理员人数" width="110"></el-table-column>
               <el-table-column label="创建时间"></el-table-column>
               <el-table-column label="状态" width="60"></el-table-column>
               <el-table-column label="备注"></el-table-column>
               <el-table-column label="操作">
                  <template slot-scope="scope">
                     <el-button type="text" @click="memberManage(true,scope.row)">
                        <el-link type="primary">成员管理</el-link>
                     </el-button>
                     <el-button type="text" @click="memberManage(false,scope.row)">
                        <el-link type="primary">模板管理</el-link>
                     </el-button>
                     <el-button type="text" @click="addEdit(false,scope.row)">
                        <el-link type="primary">编辑</el-link>
                     </el-button>
                     <el-button type="text" @click="deleteEvent(scope.row)">
                        <el-link type="primary">删除</el-link>
                     </el-button>
                  </template>
               </el-table-column>
            </el-table>
         </el-row>
         <!-- 分页 -->
         <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging" />
      </el-row>

      <el-dialog :title="dialogTitle +'旅行社信息'" :visible.sync="travelIsShow" width="550px" v-if="travelIsShow">
         <el-form class="form-dialog-box" :model="travelForm" :rules="travelRules" ref="travelForm">
            <el-form-item label="旅行社：" prop="travel">
               <el-input class="width-300" v-model="travelForm.travel" property="请输入" clearable></el-input>
            </el-form-item>
            <el-form-item label="联系人：" prop="travelName">
               <el-input class="width-300" v-model="travelForm.travelName" property="请输入" clearable></el-input>
            </el-form-item>
            <el-form-item label="联系电话：" prop="mobile">
               <el-input class="width-300" v-model="travelForm.mobile" property="请输入"  :maxlength="11" clearable></el-input>
            </el-form-item>
            <el-form-item label="状态：" prop="state">
               <el-radio v-model="travelForm.state" label="enable">启用</el-radio>
               <el-radio v-model="travelForm.state" label="disable">禁用</el-radio>
            </el-form-item>

            <el-form-item label="备注：" prop="remark">
               <el-input class="width-300 type-textarea" type="textarea" v-model="travelForm.remark" ></el-input>
            </el-form-item>
         </el-form>
         <div slot="footer" class="dialog-footer">
            <el-button @click="travelIsShow = false">取消</el-button>
            <el-button class="bg-gradient" type="primary" @click="travelDetermine('travelForm')">确定</el-button>
         </div>
      </el-dialog>

      <el-dialog :title="memberTitle === 'member' ? '成员管理' : '价格管理'" :visible.sync="memberIsShow" width="550px" v-if="memberIsShow">
         <el-form class="form-dialog-box" :model="memberForm" :rules="memberRules" ref="memberForm">
           <template v-if="memberTitle === 'member'">
              <el-form-item label="查找用户：" prop="user">
                 <el-input class="width-240" v-model="memberForm.user" property="输入姓名/昵称/电话" clearable></el-input>
                 <el-button class="bg-gradient m-left-15">查询</el-button>
              </el-form-item>
              <el-form-item label="已选择：">
                 <el-tag v-for="tag in tags" :key="tag.name" closable @close="handleClose(tag)">{{tag.name}}</el-tag>
              </el-form-item>
           </template>
            <template v-if="memberTitle === 'Template'">
               <el-form-item label="选择模板：" prop="select">
                  <el-select class="width-220"  v-model="memberForm.select"  placeholder="请选择" clearable>
                     <el-option
                           v-for="(item,index) in dictData['row-house-pms']"
                           :key="index"
                           :label="item"
                           :value="index">
                     </el-option>
                  </el-select>
               </el-form-item>
            </template>
         </el-form>
         <div slot="footer" class="dialog-footer">
            <el-button @click="memberIsShow = false">取消</el-button>
            <el-button class="bg-gradient" type="primary"  @click="memberDetermine('memberForm')">确定</el-button>
         </div>
      </el-dialog>

   </section>
</template>

<script>
import { mapState } from 'vuex';
import { urlObj } from '@/api/interface';
import { verificationRule, getDict } from '@/common/js/common'
export default {
   name: "agreementList",
   data(){
      let validMobile = (rule, value, callback) => {
         if (value === '') {
            callback(new Error('请输入联系电话'))
         }else if (verificationRule.mobile(value)){
            callback(new Error('联系电话格式错误'))
         } else {
            callback()
         }
      }
      return{
         agreement:'',     // 协议单位
         contacts:'',      // 联系人
         mobile:'',        // 联系电话

         tabState:'all',   // tabs切换
         loading: true,    // 表格加载效果
         tableData:[],     // 表格数据

         total: 0,            // 用户列表总条目数
         page: 1,             // 当前页 默认第一页
         limit: 0,            // 每页显示数

         // 创建/编辑弹窗
         dialogTitle:"",      // 弹窗title
         travelIsShow:false,  // 弹窗是否显示
         travelForm:{
            travel:'',        // 旅行社
            travelName:'',    // 联系人
            mobile:'',        // 联系电话
            state:'enable',   // 状态
            remark:'',        // 备注
         },
         travelRules:{
            travel:[{ required: true, message:'请输入旅行社' ,trigger: 'blur'}],
            travelName:[{ required: true, message:'请输入联系人' ,trigger: 'blur'}],
            mobile:[{ required: true,validator: validMobile,trigger: 'blur'}],
            state:[{ required: true, message:'请选择状态' ,trigger: 'blur'}],
         },
         // 成员管理/模板管理
         memberIsShow:false,     // 弹窗是否显示
         memberTitle:'',
         memberForm:{
            user:'',
            select:'',           // 选择模板
         },
         memberRules:{
            user:[{ required: true, message: '请输入用户名', trigger: 'change' }],
            select:[{ required: true, message: '请选择模板', trigger: 'change' }],
         },
         tags: [],

         confirmDelete:'删除后，该旅行社的价格信息和管理人员设置将会全部删除！请确认后删除。',
      }
   },
   computed: { ...mapState(['dictData','hotelInfo']) },
   mounted() {
      this.limit = sessionStorage.getItem('pageSize') * 1
      this.getList()
   },
   methods:{
      // 获取表格数据
      getList(){
         let param = {}
         // this.$axios.post(url,param).then( res => {
         //     if(res.success){
         this.loading = false
         this.tableData = [{
             name:'name',
            mobile:'137199856245'
         }]
         //     }
         // })
      },
      //tab切换
      tabClick(){
         this.getList()
      },
      //查询
      searchEvent(){
         this.getList()
      },
      //重置
      handleReset(){
         this.agreement = ''     // 协议单位
         this.contacts = ''      // 联系人
         this.mobile = ''        // 联系电话
         this.getList()
      },
      // 创建/编辑
      addEdit(bool,row){
         this.travelIsShow = true
         if(bool){
            this.dialogTitle = '创建'
         }else{
            this.dialogTitle = '编辑'
            console.log(row)
         }
      },
      // 创建/编辑 确定键
      travelDetermine(formName) {
         this.$refs[formName].validate((valid) => {
            if (valid) {
               // let param = {}
               // this.$axios.post(url,param).then(res => {
               //    if(res.success){
                  console.log('表单验证成功')
                  this.travelIsShow = false
               //    }
               // })
            } else {
               console.log('error submit!!');
               return false;
            }
         })
      },
      //成员管理/模板管理
      memberManage(bool,row){
         this.memberIsShow = true
         if(bool){
            this.memberTitle = 'member'
         //    获取成员管理 tag
         //    let param = {}
         //    this.$axios.post(url,param).then(res => {
         //       if(res.success){
                  this.tags = [
                     { name: '标签一' },
                     { name: '标签二' },
                     { name: '标签三' },
                     { name: '标签四' },
                     { name: '标签五' },]
               // this.tags = res
            //    }
            // })

         }else{
            this.memberTitle = 'Template'
            // 获取选择模板
            // let param = {}
            // this.$axios.post(url,param).then(res => {
            //    if(res.success){

            //    }
            // })
         }
      },
      // 成员管理/模板管理 确定键
      memberDetermine(formName){
         this.$refs[formName].validate((valid) => {
            if (valid) {
               // let param = {}
               // this.$axios.post(url,param).then(res =>{
               //    if(res.success){
                     console.log('表单验证成功')
                     this.memberIsShow = false
               //    }
               // })
            } else {
               console.log('error submit!!');
               return false;
            }
         })
      },
      // 关闭tags标签页
      handleClose(tag) {
         this.tags.splice(this.tags.indexOf(tag), 1);
      },
      //删除
      deleteEvent(row){
         this.$confirm(this.confirmDelete, '提醒', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning'
         }).then(() => {
            // let param = {}
            // this.$axios.post(url,param).then(res => {
            //    if(res.success){
                  console.log(row,'rowId')
                  this.$message({
                     type: 'success',
                     message: '删除成功!'
                  });
            //    }
            // })
         }).catch(() => {
            this.$message({
               type: 'info',
               message: '已取消删除'
            });
         });
      },




      // 改变每页数
      pageChange(num) {
         this.limit = num
         this.getMember()
      },
      // 改变当前页
      handlePaging(num) {
         this.page = num
         this.getMember()
      },
   }
}
</script>

<style scoped lang="scss">
.agreementList{
   .tab-box{
      ::v-deep .el-tabs__header {
         margin: 0 0 31px;
         border-bottom: 1px solid #E3E4E5;
         .el-tabs__item{
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #2D2D2D;
         }
         .is-active{
            font-size: 18px;
            font-weight: bold;
            color: #2577E3;
         }
      }
   }
}
</style>
